import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import InfoList from '../components/info-list.js'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query InfoPageQuery {
    sanityInfoPage(title: {eq: "info"}) {
      title
      _rawBody
    }
  }
`

const InfoPage = props => {
  const {data, errors} = props
  const {title, text} = data.sanityInfoPage
  const post = data && data.sanityInfoPage
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO title='Productos' />
      <Container>
        <h1 className={responsiveTitle1}>{ title }</h1>  
        <h1 className={responsiveTitle1}> </h1>  
        {post && <InfoList {...post} />}
      </Container>
    </Layout>
  )
}

export default InfoPage
